import React, { Fragment, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigation } from '@common/helpers/navigation';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import { shouldNotAddToken } from '@common/utils/auth';
import { handleApiCallErrorNotification } from '@common/utils/notifications';
import { setAuthToken } from '@setup/redux/reducers/authSlice';
import { setTranscriptionMinutes } from '@setup/redux/reducers/statsSlice';
import { removeUser } from '@setup/redux/reducers/userSlice';
import Notification from './common/components/Notification';
import RouterManager from './setup/router-manager/RouterManager';

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const notification = useNotification();
    const authToken = useSelector((state) => state.auth.authToken);
    const userPlan = useSelector((state) => state.user.userPlan);
    const { handleUpdateUserData } = useUser();

    // introducing auth token in each request api call
    axios.interceptors.request.use(
        function (apiCallConfig) {
            if (shouldNotAddToken(apiCallConfig)) {
                return apiCallConfig;
            }
            if (authToken && !apiCallConfig.headers[navigation.auth.token]) {
                apiCallConfig.headers = {
                    ...apiCallConfig.headers,
                    [navigation.auth.token]: authToken
                };
            }
            return apiCallConfig;
        },
        function (error) {
            console.log(`⚠ ${error.message}`, error);
            return Promise.reject(error);
        }
    );

    // checking response status of each api call
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            console.log(`🚨 ${error.message}`, error.response);
            if (error.response.status === 401 || error.response.status === 403) {
                // auth error
                dispatch(setAuthToken(null));
                dispatch(setTranscriptionMinutes(null));
                dispatch(removeUser());
            }
            handleApiCallErrorNotification({
                status: error.response.status,
                message: error.response.data.message,
                notification
            });
            return Promise.reject(error);
        }
    );

    const handleAuth = () => {
        // Plugin now redirect always to the login page
        if (!authToken || !userPlan) {
            // ! Be careful with routes that need or don´t need auth
            if (
                location.pathname === navigation.app.routes.repository &&
                location.hash === navigation.extension.hash.signin
            ) {
                // For redirecting when no auth coming from plugin extension
                // ! TODO: the problem is that when signin, the page don´t reload so the extension dont load the client.ts
                navigate(`${navigation.app.routes.login}${location.search}${location.hash}`);
            } else if (
                location.pathname === navigation.app.routes.repository ||
                (location.pathname.includes(navigation.app.routes.meeting) &&
                    !location.pathname.includes(navigation.app.routes.meetingShared)) ||
                location.pathname.includes(navigation.app.routes.settings) ||
                location.pathname === navigation.app.routes.recorder
            ) {
                // ! Be careful with the logic for meeting-shared
                navigate(navigation.app.routes.login);
            }
        } else {
            if (location.pathname === navigation.app.routes.login) {
                navigate(navigation.app.routes.repository);
            }
            (async () => {
                await handleUpdateUserData();
            })();
        }
    };

    const handlePluginUploadAuth = () => {
        const handleMessage = (event) => {
            if (event.source === window && event.data.type === 'AUTH_TOKEN') {
                const token = event.data.token;

                if (authToken !== token) {
                    // Set the new token to redux
                    dispatch(setAuthToken(token));

                    (async () => {
                        // Get user data from the new token
                        await handleUpdateUserData(token);
                        navigate(
                            `${navigation.app.routes.repository}${location.search}${location.hash}`
                        );
                    })();
                } else {
                    navigate(
                        `${navigation.app.routes.repository}${location.search}${location.hash}`
                    );
                }

                window.removeEventListener('message', handleMessage);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    };

    useEffect(() => {
        // Auth check during execution and first render
        // ! Be careful with the params into the array that triggers the use effect can cause loops
        if (location.hash.includes(navigation.extension.hash.submit)) {
            // ! This will be trigger with each route change
            handlePluginUploadAuth();
        } else {
            handleAuth();
        }
    }, [location.pathname]);

    return (
        <Fragment>
            <RouterManager />
            <Notification />
        </Fragment>
    );
}

export default App;
