import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import { getStartMeetingTimeArray, utcToLocaleDate } from '@common/utils/date';
import { getMeetingById } from '@setup/api/meeting/meeting';
import { getSharedMeetingById } from '@setup/api/meeting/sharedMeeting';
import { getFirstSpeaker } from '@setup/api/meetingInterventions/meetingInterventions';
import { getSharedFirstSpeaker } from '@setup/api/meetingInterventions/sharedMeetingInterventions';
import { getMeetingNotes } from '@setup/api/notes';
import { bulkUpdateReportBlocksWords, getReportBlocks } from '@setup/api/reportBlocks/reportBlocks';
import { getSharedReportBlocks } from '@setup/api/reportBlocks/sharedReportBlocks';
import MeetingPageView from './MeetingPageView';

export default function MeetingPage() {
    const notification = useNotification();

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const isShared = searchParams.has('t');
    const isFromPremiumUser = searchParams.has('p'); // premium users url has p letter to distinguish
    const token = isShared ? searchParams.getAll('t') : null;
    // TODO: if it is shared but you have account you should be able to go to your home
    const { getPlan } = useUser();

    const [meeting, setMeeting] = useState(null);
    const [time, setTime] = useState(null);
    const [date, setDate] = useState(null);
    const [firstSpeaker, setFirstSpeaker] = useState(null);
    const [blocks, setBlocks] = useState(null);
    const [loading, setLoading] = useState(false);
    const [meetingNotes, setMeetingNotes] = useState(null);

    const handleCalculateDateInfo = (creationDate, duration) => {
        const dateOptions = {
            weekday: 'long',
            day: '2-digit',
            month: 'long'
        };
        const startMeetingTimeArr = getStartMeetingTimeArray({
            utcDate: creationDate,
            timeZone: 'es-ES',
            duration
        });
        setTime(`${startMeetingTimeArr[0]}:${startMeetingTimeArr[1].toString().padStart(2, 0)}`);
        setDate(
            utcToLocaleDate({
                utcDate: creationDate,
                timeZone: 'es-ES',
                personalizedOptions: dateOptions
            })
        );
    };

    const handleGetMeeting = async (meetingId) => {
        try {
            const { meeting } = isShared
                ? await getSharedMeetingById({ meetingId, authToken: token[0] })
                : await getMeetingById({ meetingId });
            handleCalculateDateInfo(meeting.createdAt, meeting.duration);
            setMeeting(meeting);
        } catch (error) {
            console.error(error);
        }
    };

    // TODO: how to check it with authToken
    const handleGetFirstSpeaker = async (meetingId) => {
        try {
            const { speaker } = isShared
                ? await getSharedFirstSpeaker({ meetingId, authToken: token[0] })
                : await getFirstSpeaker({ meetingId });
            setFirstSpeaker(speaker);
        } catch (error) {
            console.error(error);
        }
    };

    const getReportBlocksHandler = async (meetingId) => {
        const { reportBlocks, getReportBlocksStatus } = isShared
            ? await getSharedReportBlocks({ meetingId, authToken: isShared ? token[0] : null })
            : await getReportBlocks({ meetingId });
        if (getReportBlocksStatus === 200) {
            setBlocks(reportBlocks.length !== 0 ? reportBlocks : null);
        } else {
            notification('retrieving-blocks-error');
        }
    };

    const handleUpdateBlocksWords = async ({ meetingId, currentWords, newWords }) => {
        const { updatedReportBlocks, updatedReportBlocksStatus } =
            await bulkUpdateReportBlocksWords({
                meetingId,
                currentWords,
                newWords
            });
        if (updatedReportBlocksStatus === 200) {
            setBlocks(updatedReportBlocks.length !== 0 ? updatedReportBlocks : null);
        } else {
            notification('updating-blocks-error');
        }
    };

    const handleGetMeetingNotes = async (id) => {
        const notes = await getMeetingNotes(id);
        return notes;
    };

    useEffect(() => {
        (async () => {
            await handleGetMeeting(id);
            if (getPlan()?.name !== subscriptionPlans.free.name) await handleGetFirstSpeaker(id);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const notes = await handleGetMeetingNotes(id);
            setMeetingNotes(notes);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getReportBlocksHandler(id);
            setLoading(false);
        })();
    }, []);

    if (meeting !== null) {
        return (
            <MeetingPageView
                meeting={meeting}
                date={date}
                time={time}
                firstSpeaker={firstSpeaker}
                isShared={isShared}
                authToken={isShared ? token[0] : null}
                reportBlocks={blocks}
                setBlocks={setBlocks}
                getReportBlocksHandler={getReportBlocksHandler}
                loading={loading}
                isFromPremiumUser={isFromPremiumUser}
                handleUpdateBlocksWords={handleUpdateBlocksWords}
                meetingNotes={meetingNotes}
            />
        );
    }
}
