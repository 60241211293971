import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import DialogButton from '@common/components/dialogs/DialogButton';
import OptionsSelector from '@common/components/form/OptionsSelector';
import useCases from '@common/components/form/selectorData/useCases.json';
import Page from '@common/components/Page';
import { getUser, updateUserData } from '@setup/api/user';
import Dashboard from './components/Dashboard';
import Table from './components/table/Table';

export default function MeetingsRepository() {
    const monthlyMeetings = useSelector((state) => state.stats.monthlyMeetings);
    const transcriptionMinutes = useSelector((state) => state.stats.transcriptionMinutes);
    const totalMeetings = useSelector((state) => state.stats.totalMeetings);

    const [openDialog, setOpenDialog] = useState(false);
    const [useCase, setUseCase] = useState({});

    const handleUpdateUserUseCase = async () => {
        const { updateUserStatus } = await updateUserData({
            useCase: useCase.type
        });
        return updateUserStatus === 200;
    };

    useEffect(() => {
        (async () => {
            const userData = await getUser();
            if (!userData.useCase) {
                setOpenDialog(true);
            }
        })();
    }, []);

    return (
        <Page title='Tus reuniones'>
            <Box sx={{ display: 'flex', paddingTop: 8 }}>
                <Box
                    component='main'
                    sx={{
                        backgroundColor: 'white',
                        flexGrow: 1,
                        height: 'auto',
                        overflow: 'auto'
                    }}
                >
                    <Box>
                        <Container maxWidth='lg' sx={{ mt: 2, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Dashboard
                                        monthlyMeetings={monthlyMeetings}
                                        monthlyMinutes={transcriptionMinutes}
                                        totalMeetings={totalMeetings}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Table />
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </Box>
            {/* TODO: Temporaly pop-up - delete after usage */}
            <DialogButton
                dialogTitle='Cuéntanos sobre tu caso de uso con Voicit'
                dialogText='Queremos optimizar la herramienta para ti y necesitamos conocer tu caso de uso'
                withButton={false}
                successButtonLoadingText='Enviando...'
                successButtonText='Enviar'
                onSuccess={() => handleUpdateUserUseCase()}
                openDialog={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth={'md'}
            >
                <OptionsSelector
                    options={useCases}
                    label='¿Qué tipo de reuniones optimizas con Voicit?'
                    handleChange={(value) => setUseCase(value)}
                />
            </DialogButton>
        </Page>
    );
}
