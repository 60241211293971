import { authApiCall, endpoints } from '.';

export const chat = async ({ newMessage, thread, meetingId }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/chat/${meetingId}`,
        data: {
            newMessage,
            thread
        }
    });

    return data;
};
