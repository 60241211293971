import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transcriptionMinutes: null,
    monthlyMeetings: null,
    totalMeetings: null
};

export const statsSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        setTranscriptionMinutes: (state, action) => {
            state.transcriptionMinutes = action.payload;
        },
        setMonthlyMeetings: (state, action) => {
            state.monthlyMeetings = action.payload;
        },
        setTotalMeetings: (state, action) => {
            state.totalMeetings = action.payload;
        }
    }
});

export const { setTranscriptionMinutes, setMonthlyMeetings, setTotalMeetings } = statsSlice.actions;
export default statsSlice.reducer;
