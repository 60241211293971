import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoaderTranscription from '@common/components/loaders/LoaderTranscription';
import useNotification from '@common/hooks/useNotification';
import { secondsToTimeKey } from '@common/utils/date';
import {
    deleteMeetingIntervention,
    getAllMeetingInterventions,
    getMeetingInterventionSpeakersWithTurn,
    getPaginatedMeetingInterventions,
    getPaginatedMeetingInterventionsBySpeaker,
    updateMeetingInterventionLines
} from '@setup/api/meetingInterventions/meetingInterventions';
import {
    getSharedMeetingInterventionSpeakersWithTurn,
    getSharedPaginatedMeetingInterventions,
    getSharedPaginatedMeetingInterventionsBySpeaker
} from '@setup/api/meetingInterventions/sharedMeetingInterventions';
import TranscriptionView from './TranscriptionView';

Transcription.propTypes = {
    meeting: PropTypes.object.isRequired,
    showInterDetails: PropTypes.bool.isRequired,
    isShared: PropTypes.bool.isRequired,
    authToken: PropTypes.string,
    platform: PropTypes.string,
    speakerTurn: PropTypes.bool.isRequired,
    currentAudioTime: PropTypes.number.isRequired,
    setCurrentAudioTime: PropTypes.func.isRequired,
    enableAutomaticAudioSync: PropTypes.bool.isRequired,
    slideChange: PropTypes.number.isRequired
};

export default function Transcription({
    meeting,
    showInterDetails,
    isShared,
    authToken,
    platform,
    speakerTurn,
    currentAudioTime,
    setCurrentAudioTime,
    enableAutomaticAudioSync,
    slideChange
}) {
    const notification = useNotification();

    const [interventions, setInterventions] = useState(null);
    const [loadingSpeakers, setLoadingSpeakers] = useState(false);
    const [rowCount, setRowCount] = useState(5);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(10);
    const [bottom, setBottom] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [filterSpeakerTag, setFilterSpeakerTag] = useState('');
    const [speakersWithTurn, setSpeakersWithTurn] = useState(null);

    const isDirectUpload = platform ? false : true;

    const handlePagination = async (selectedPage, selectedPageSize) => {
        console.log('Doing pagination...');
        setLoadingSpeakers(true);
        let interventionData = null;
        if (filterSpeakerTag) {
            interventionData = isShared
                ? await getSharedPaginatedMeetingInterventionsBySpeaker({
                      meetingId: meeting.id,
                      page: selectedPage,
                      pageSize: selectedPageSize,
                      filterSpeakerTag,
                      authToken
                  })
                : await getPaginatedMeetingInterventionsBySpeaker({
                      meetingId: meeting.id,
                      page: selectedPage,
                      pageSize: selectedPageSize,
                      filterSpeakerTag
                  });
        } else {
            interventionData = isShared
                ? await getSharedPaginatedMeetingInterventions({
                      meetingId: meeting.id,
                      page: selectedPage,
                      pageSize: selectedPageSize,
                      authToken
                  })
                : await getPaginatedMeetingInterventions({
                      meetingId: meeting.id,
                      page: selectedPage,
                      pageSize: selectedPageSize
                  });
        }

        setInterventions(
            selectedPage === 0
                ? interventionData.interventions
                : [...(interventions || []), ...interventionData.interventions]
        );
        setRowCount(interventionData.count);
        setLoadingSpeakers(false);
        setBottom(false);
    };

    const handleChargeMore = async () => {
        setLoadingSpeakers(true);
        setPage(page + 1);
        await handlePagination(page + 1, pageSize);
        setLoadingSpeakers(false);
    };

    const handleChargeFullTranscription = async () => {
        const { interventions: fullTranscription } = await getAllMeetingInterventions({
            meetingId: meeting.id
        });
        setInterventions(fullTranscription);
        return fullTranscription;
    };

    const handleUpdateSpeakersContent = (oldName, newName) => {
        setInterventions((currentContent) =>
            currentContent.map((intervention) => {
                if (intervention.name === oldName) {
                    return { ...intervention, name: newName };
                }
                return intervention;
            })
        );
    };

    const handleDeleteIntervention = async (interventionId) => {
        try {
            await deleteMeetingIntervention({
                meetingId: meeting.id,
                meetingInterventionId: interventionId
            });
            setInterventions(
                interventions.filter((intervention) => intervention.id !== interventionId)
            );
        } catch (error) {
            notification('intervention-deletion-failure');
        }
    };

    const handleUpdateIntervention = async (meetingInterventionId, newLines) => {
        try {
            await updateMeetingInterventionLines({
                meetingId: meeting.id,
                newLines,
                meetingInterventionId
            });
        } catch (error) {
            notification('intervention-update-failure');
        }
    };

    useEffect(() => {
        setPage(0);
        handlePagination(0, pageSize);
    }, [filterSpeakerTag, speakerTurn]);

    useEffect(() => {
        const handleScroll = () => {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = window.scrollY + window.innerHeight;
            // When the user is [modifier]px from the bottom, fire the event.
            let modifier = 200;
            if (currentScroll + modifier > documentHeight) {
                setBottom(!bottom); // alter bottom state
            }
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /**
     * Detects if we have scroll to the bottom to charge more interventions
     */
    useEffect(() => {
        if (bottom && page <= Math.round(rowCount / pageSize)) {
            handleChargeMore();
        }
    }, [bottom]);

    /**
     * If the meeting is shared, uses the shared route
     */
    useEffect(() => {
        (async () => {
            if (meeting.speakers.length > 1) {
                const speakers = isShared
                    ? await getSharedMeetingInterventionSpeakersWithTurn(meeting.id, authToken)
                    : await getMeetingInterventionSpeakersWithTurn(meeting.id);
                setSpeakersWithTurn(speakers);
            }
        })();
    }, []);

    /**
     * Automatically syncs audio with interventions
     */
    useEffect(() => {
        if (enableAutomaticAudioSync) {
            (async () => {
                const automaticSyncAudioWithTranscription = async () => {
                    if (interventions) {
                        const audioTimeKey = secondsToTimeKey(slideChange);
                        const foundIntervention = interventions.find((intervention) => {
                            return (
                                audioTimeKey >= intervention.timeKey1 &&
                                audioTimeKey < intervention.timeKey1 + intervention.timeKey2
                            );
                        });
                        if (foundIntervention) {
                            const element = document.getElementById(
                                `${foundIntervention.timeKey1}`
                            );

                            if (element) {
                                element.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'nearest'
                                });
                            }
                        } else {
                            const fullTranscription = await handleChargeFullTranscription();
                            const foundIntervention = fullTranscription.find((intervention) => {
                                return (
                                    audioTimeKey >= intervention.timeKey1 &&
                                    audioTimeKey < intervention.timeKey1 + intervention.timeKey2
                                );
                            });

                            if (foundIntervention) {
                                // As we have to charge the full transcription, the element couldn´t be created yet
                                const elementId = `${foundIntervention.timeKey1}`;

                                const scrollElementIntoView = () => {
                                    const element = document.getElementById(elementId);
                                    if (element) {
                                        element.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center',
                                            inline: 'nearest'
                                        });
                                        observer.disconnect(); // Stop observing once the element is found
                                    }
                                };

                                // Create a MutationObserver to observe changes in the DOM
                                const observer = new MutationObserver(scrollElementIntoView);

                                // Start observing the target node (in this case, the document body)
                                observer.observe(document.body, {
                                    childList: true, // Observe changes to the child nodes
                                    subtree: true // Include all descendant nodes
                                });

                                // Call scrollElementIntoView immediately to check if the element already exists
                                scrollElementIntoView();
                            }
                        }
                    }
                };

                await automaticSyncAudioWithTranscription();
            })();
        }
    }, [slideChange]);

    if (interventions) {
        const speakersList = speakersWithTurn
            ? speakersWithTurn.map((s) => {
                  return { label: s.name };
              })
            : null;

        return (
            <TranscriptionView
                meeting={meeting}
                handleChargeMore={handleChargeMore}
                loadingSpeakers={loadingSpeakers}
                handleUpdateIntervention={handleUpdateIntervention}
                handleDeleteIntervention={handleDeleteIntervention}
                handleUpdateSpeakersContent={handleUpdateSpeakersContent}
                interventions={interventions}
                scrollY={scrollY}
                showInterDetails={showInterDetails}
                speakersList={speakersList}
                handlePagination={handlePagination}
                setFilterSpeakerTag={setFilterSpeakerTag}
                filterSpeakerTag={filterSpeakerTag}
                setInterventions={setInterventions}
                isShared={isShared}
                isDirectUpload={isDirectUpload}
                currentAudioTime={currentAudioTime}
                setCurrentAudioTime={setCurrentAudioTime}
            />
        );
    } else {
        return <LoaderTranscription />;
    }
}
