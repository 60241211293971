import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RedeemIcon from '@mui/icons-material/Redeem';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { Chip, Grid, Link, Radio, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import DialogButton from '@common/components/dialogs/DialogButton';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import palette from '@common/theme/palette/palette';
import { sendChromeStoreNotification } from '@common/utils/user';
import productReview from '../../../assets/images/productReview.png';
import HorizontalCard from '../cards/HorizontalCard';
import IconPhrase from '../icons/IconPhrase';
import ToggleButton from './plansDialog/ToggleButton';

PricingDialog.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    button: PropTypes.bool,
    title: PropTypes.string,
    defaultPlanId: PropTypes.number
};

export default function PricingDialog({ openDialog, setOpenDialog, button, title, defaultPlanId }) {
    const planId = defaultPlanId ? defaultPlanId : 0;
    const plans = [subscriptionPlans.premium, subscriptionPlans.unlimited];

    const userEmail = useSelector((state) => state.user.userEmail);
    const givenName = useSelector((state) => state.user.userGivenName);

    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('yearly');
    const [selectedPlan, setSelectedPlan] = useState(planId);

    // -- Below to edit the text HTML of price options
    // const monthlyPriceHtml =
    //     selectedPlan === 2
    //         ? plans[2].monthlyPrice
    //         : (selectedPlan === 0
    //               ? `${plans[0].monthlyPriceDisccount}€/mes`
    //               : `${plans[1].monthlyPriceDisccount}€/mes`) +
    //           (selectedPlan !== 2
    //               ? selectedPlan === 0
    //                   ? ` <s>${Number(plans[0].monthlyPrice)}€</s>`
    //                   : ` <s> ${Number(plans[1].monthlyPrice)}€</s>`
    //               : '');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlePlansChange = (planId) => {
        // TODO: should be guided by the toggle button based on the number of options
        setSelectedPlan(planId);
    };

    const handleChromeReviewClick = async () => {
        window.open(urls.chromePlugin, '_blank');
        await sendChromeStoreNotification(givenName, userEmail);
    };

    useEffect(() => {
        if (setOpenDialog) {
            setOpenDialog(open);
        }
    }, [open]);

    useEffect(() => {
        if (openDialog) {
            setOpen(true);
        }
    }, [openDialog]);

    return (
        <DialogButton
            id='clickPremiumFeature'
            openDialog={open}
            withButton={button ? true : false}
            buttonText={'Actualizar plan'}
            buttonVariant='outlined'
            buttonColor='primary'
            buttonSize='small'
            buttonIcon={<StarTwoToneIcon color={'yellow'} sx={{ mr: 1 }} />}
            dialogTitle={title ? title : 'Actualiza a un plan mejor'}
            dialogIcon={<StarTwoToneIcon color={'yellow'} sx={{ mr: 1 }} />}
            onClose={() => {
                setOpen(false);
                setSelectedPlan(planId);
            }}
            successButtonText={'Actualizar plan'}
            onSuccess={() => {
                window.open(
                    selectedOption === 'yearly'
                        ? plans[selectedPlan].annuallyPaymentUrl
                        : plans[selectedPlan].monthlyPaymentUrl
                );
            }}
            buttonTooltipText='Utiliza plantillas y secciones de informes personalizadas'
            buttonTooltipImage={productReview}
            buttonTooltipTitle='Saca el máximo partido de Voicit'
            maxWidth='sm'
        >
            <Grid container direction='column' spacing={2}>
                {/* Description */}
                {/* <Grid item>
                    <Typography component='span' variant='subtitle1' color={palette.primary[300]}>
                        Actualiza al plan premium para desbloquear esta funcionalidad y muchas más.
                    </Typography>
                </Grid> */}

                {/* Selector */}
                {plans.length > 1 && (
                    <Grid item>
                        <ToggleButton
                            options={plans}
                            defaultOptionId={planId}
                            fullWidth={true}
                            handleChange={handlePlansChange}
                        />
                    </Grid>
                )}

                {/* Plans features */}
                <Grid item>
                    <Grid container direction='column' spacing={1}>
                        {plans[selectedPlan].features.map((feature) => {
                            return (
                                <Grid item key={feature.key}>
                                    <IconPhrase
                                        key={feature.key}
                                        icon={feature.icon}
                                        text={feature.text}
                                        color={palette.primary.main}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>

                {/* Pricing web */}
                <Grid item>
                    <Link
                        underline='hover'
                        sx={{ display: 'flex', alignItems: 'center' }}
                        component='button'
                        color={palette.primary[300]}
                        onClick={() => {
                            window.open(urls.payments.pricing);
                        }}
                    >
                        <InfoOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                        <Typography component='span' variant='body2' color={palette.primary[400]}>
                            Ver más detalles y comparar planes
                        </Typography>
                    </Link>
                </Grid>

                {/* Plan monthly */}
                <Grid item ml={1} mt={1}>
                    <Grid container direction='row' spacing={2}>
                        <Grid item xs={12} sm={12} mb={0}>
                            <Grid
                                container
                                direction='row'
                                spacing={1}
                                alignItems={'center'}
                                sx={{
                                    pb: 1,
                                    border: '1px solid black',
                                    borderColor:
                                        selectedOption === 'monthly'
                                            ? palette.primary.main
                                            : palette.primary[50],
                                    borderRadius: '4px',
                                    background:
                                        selectedOption === 'monthly' ? palette.primary[50] : null
                                }}
                            >
                                <Grid item>
                                    <Radio
                                        checked={selectedOption === 'monthly'}
                                        onChange={handleChange}
                                        value='monthly'
                                        name='radio-buttons'
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component='span'
                                        variant='subtitle2'
                                        color={palette.primary.main}
                                    >
                                        {'Mensual '}
                                        <br></br>
                                        <Typography
                                            component='span'
                                            variant='body2'
                                            color={palette.primary.main}
                                        >
                                            {`${plans[selectedPlan].monthlyPrice}€/mes `}
                                        </Typography>
                                        <Typography
                                            component='span'
                                            variant='body2'
                                            color={palette.primary.main}
                                            // dangerouslySetInnerHTML={{ __html: monthlyPriceHtml }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Plan annually */}
                        <Grid item xs={12} sm={12} mb={0}>
                            <Grid
                                container
                                direction='row'
                                spacing={1}
                                alignItems={'center'}
                                sx={{
                                    pb: 1,
                                    border: '1px solid black',
                                    borderColor:
                                        selectedOption === 'yearly'
                                            ? palette.primary.main
                                            : palette.primary[50],
                                    borderRadius: '4px',
                                    background:
                                        selectedOption === 'yearly' ? palette.primary[50] : null
                                }}
                            >
                                <Grid item>
                                    <Radio
                                        checked={selectedOption === 'yearly'}
                                        onChange={handleChange}
                                        value='yearly'
                                        name='radio-buttons'
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component='span'
                                        variant='subtitle2'
                                        color={palette.primary.main}
                                    >
                                        {'Anual'} <br></br>
                                        <Typography
                                            component='span'
                                            variant='body2'
                                            color={palette.primary.main}
                                        >
                                            {`${plans[selectedPlan].annuallyPrice}€/mes `}
                                            {
                                                <Chip
                                                    label={`Ahorras ${
                                                        Number(plans[selectedPlan].monthlyPrice) *
                                                            12 -
                                                        Number(plans[selectedPlan].annuallyPrice) *
                                                            12
                                                    }€ `}
                                                    variant='contained'
                                                    size='small'
                                                    color='chip_secondary_soft'
                                                />
                                            }
                                        </Typography>
                                    </Typography>
                                </Grid>
                                {/* Button inside option */}
                                {/* {selectedPlan === 1 ? null : (
                                    <Grid item flexGrow={1} sx={{ ml: 1, mr: 2 }}>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            fullWidth={true}
                                            disableElevation={true}
                                            onClick={() => {
                                                window.open(urls.calendar.salesCalendar);
                                            }}
                                        >
                                            Agendar reunión
                                        </Button>
                                    </Grid>
                                )} */}
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mt: -2, mb: 2 }}>
                            <Typography
                                component='span'
                                variant='caption'
                                color={palette.primary[300]}
                            >
                                * Se aplicará un 20% de descuento para países de Latino América.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Chrome review */}
                <Grid item sx={{ mt: -1 }}>
                    <HorizontalCard
                        text=' 7 días más de prueba dejando tu reseña en Chrome'
                        icon={<RedeemIcon fontSize='small' />}
                        // link={urls.chromePlugin}
                        onClick={handleChromeReviewClick}
                    />
                </Grid>
                <Grid item sx={{ mt: -2 }}>
                    <Typography component='span' variant='caption' color={palette.primary[300]}>
                        * Puede que tardemos hasta 24h en revisar y reactivar tu prueba.
                    </Typography>
                </Grid>
            </Grid>
        </DialogButton>
    );
}
