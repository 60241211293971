import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { PropTypes } from 'prop-types';
import CreateMeetingDialog from '../createMeetingDialog/CreateMeetingDialog';
import MeetingDialog from '../meetingDialog/MeetingDialog';
import TableFooter from './TableFooter';
import TableRow from './tableRow/TableRow';
import TableToolbar from './tableToolbar/TableToolbar';

TableView.propTypes = {
    handleOpenMeeting: PropTypes.func,
    meetingList: PropTypes.arrayOf(PropTypes.object),
    setMeetingList: PropTypes.func,
    handleCloseDialog: PropTypes.func,
    openDialog: PropTypes.bool,
    loadingDialog: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rowCount: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleDeleteMeeting: PropTypes.func,
    currentMeeting: PropTypes.object,
    findAndUpdateMeeting: PropTypes.func,
    handleSearch: PropTypes.func,
    introduceNewMeeting: PropTypes.func.isRequired,
    handleResetMeetingList: PropTypes.func,
    children: PropTypes.element,
    handleRetryMeeting: PropTypes.func,
    isAudioFromExtension: PropTypes.bool.isRequired,
    setIsAudioFromExtension: PropTypes.func.isRequired
};

export default function TableView(props) {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TableToolbar
                    currentMeeting={props.currentMeeting}
                    // ! Waiting to validate the new create meeting dialog before refactoring the full component
                    isAudioFromExtension={false}
                    introduceNewMeeting={props.introduceNewMeeting}
                    handleSearch={props.handleSearch}
                    handleResetMeetingList={props.handleResetMeetingList}
                    handleDeleteMeeting={props.handleDeleteMeeting}
                    rowCount={props.rowCount}
                    handleRetryMeeting={props.handleRetryMeeting}
                    findAndUpdateMeeting={props.findAndUpdateMeeting}
                />
                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table aria-label='custom pagination table'>
                        {/* <TableHead /> */}
                        <TableBody>
                            {props.meetingList ? (
                                <TableRow
                                    handleOpenMeeting={props.handleOpenMeeting}
                                    handleDeleteMeeting={props.handleDeleteMeeting}
                                    meetingList={props.meetingList}
                                    handleRetryMeeting={props.handleRetryMeeting}
                                    findAndUpdateMeeting={props.findAndUpdateMeeting}
                                />
                            ) : (
                                <>{props.children}</>
                            )}
                        </TableBody>
                        {props.meetingList && (
                            <TableFooter
                                page={props.page}
                                rowsPerPage={props.rowsPerPage}
                                rowCount={props.rowCount}
                                handleChangePage={props.handleChangePage}
                                handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                meetingList={props.meetingList}
                                setMeetingList={props.setMeetingList}
                            />
                        )}
                    </Table>
                </TableContainer>
                {props.currentMeeting && props.openDialog && (
                    <MeetingDialog
                        open={props.openDialog}
                        handleClose={props.handleCloseDialog}
                        loadingDialog={props.loadingDialog}
                        currentMeeting={props.currentMeeting}
                        findAndUpdateMeeting={props.findAndUpdateMeeting}
                        handleOpenMeeting={props.handleOpenMeeting}
                    />
                )}
                {props.isAudioFromExtension && (
                    <CreateMeetingDialog
                        currentMeeting={props.currentMeeting}
                        handleDeleteMeeting={props.handleDeleteMeeting}
                        handleRetryMeeting={props.handleRetryMeeting}
                        findAndUpdateMeeting={props.findAndUpdateMeeting}
                        isDirectUpload={false}
                        setIsAudioFromExtension={props.setIsAudioFromExtension}
                    />
                )}
            </Box>
        </>
    );
}
