import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Chip, TableCell, Tooltip } from '@mui/material';
import { PropTypes } from 'prop-types';
import TooltipIcon from '@common/components/icons/TooltipIcon';
import Tag from '@common/components/tagsComponents/Tag';
import palette from '@common/theme/palette/palette';
import { fromSecondsToMinutes } from '@common/utils/date';

export default function InfoCell({
    currentTagData,
    numberOfParticipants,
    meeting,
    errorState,
    handleOpenMeeting,
    hover,
    isStandBy
}) {
    return (
        <TableCell
            sx={{
                borderColor: palette.primary[50],
                cursor: errorState ? null : 'pointer'
            }}
            key={meeting.tag}
            style={{ width: 300 }}
            align='left'
            onClick={errorState ? null : (event) => handleOpenMeeting(meeting, event)}
            onMouseDown={(event) => {
                if (event.button === 1) handleOpenMeeting(meeting, event);
            }}
        >
            {hover ? (
                <>
                    <Tooltip title='Participantes'>
                        <Chip
                            icon={<PeopleAltOutlinedIcon />}
                            size='small'
                            label={numberOfParticipants}
                            sx={{ mr: 1, mb: 0 }}
                        />
                    </Tooltip>
                    <Tooltip title='Duración en minutos'>
                        <Chip
                            size='small'
                            label={`${fromSecondsToMinutes(meeting.duration)} min`}
                            sx={{ mr: 1, mb: 0 }}
                        />
                    </Tooltip>
                </>
            ) : null}
            {errorState ? (
                <>
                    <Tag
                        tag={{
                            name: 'Error',
                            color: 'chip_error',
                            id: null
                        }}
                    />
                    <TooltipIcon
                        sx={{ ml: 1 }}
                        icon={<InfoOutlinedIcon fontSize='small' />}
                        tooltipText='Haz clic en reintentar. Contacta con soporte@voicit.com si el problema persiste.'
                    />
                </>
            ) : isStandBy ? (
                <>
                    <Tag
                        tag={{
                            name: 'En espera',
                            color: 'chip_primary_soft',
                            id: null
                        }}
                    />
                    <TooltipIcon
                        sx={{ ml: 1 }}
                        icon={<InfoOutlinedIcon fontSize='small' />}
                        tooltipText='Haz clic en generar, no ha comenzado a procesarse.'
                    />
                </>
            ) : currentTagData ? (
                <Tag tag={currentTagData} />
            ) : null}
        </TableCell>
    );
}

InfoCell.propTypes = {
    currentTagData: PropTypes.object,
    numberOfParticipants: PropTypes.number,
    meeting: PropTypes.object,
    errorState: PropTypes.bool,
    handleOpenMeeting: PropTypes.func,
    hover: PropTypes.bool,
    isStandBy: PropTypes.bool.isRequired
};
