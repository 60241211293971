export const navigation = {
    app: {
        routes: {
            login: '/login',
            recorder: '/recorder',
            meeting: '/meeting',
            settings: '/settings',
            repository: '/',
            signup: '/signup',
            templates: '/templates',
            newTemplate: '/templates/create',
            editTemplate: '/templates/edit',
            passwordReset: '/password-reset',
            meetingShared: '/meeting-shared',
            pluginOnboarding: '/plugin-onboarding'
        },
        hash: {
            retry: '#retry'
        }
    },
    auth: {
        token: 'x-voicit-auth'
    },
    extension: {
        hash: {
            signin: '#ext-signin',
            submit: '#ext-submit'
        }
    }
};
