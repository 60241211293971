import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';
import countries from './selectorData/countries.json';

MobilePhoneNumber.propTypes = {
    alertMessage: PropTypes.string,
    setMobileNumber: PropTypes.func,
    sx: PropTypes.object,
    variant: PropTypes.string
};

export default function MobilePhoneNumber({ alertMessage, setMobileNumber, sx, variant }) {
    const [currentCountry, setCountry] = useState(
        countries.find((country) => country.code === 'ES')
    );
    const [number, setNumber] = useState('');

    const handleChangePhoneNumber = (event) => {
        setNumber(event.target.value);
        setMobileNumber({
            countryCode: currentCountry.phone,
            number: event.target.value
        });
    };

    const handleChangeCountry = (event, country) => {
        setCountry(country);
        setMobileNumber({
            countryCode: country.phone,
            number: number
        });
    };

    return (
        <Stack direction='row' flexWrap='wrap' spacing={1} sx={{ ...sx, flexGrow: 1 }}>
            <Autocomplete
                id='mobile-phone-number'
                options={countries}
                disableClearable={true}
                autoHighlight
                sx={{ flexGrow: 1 }}
                getOptionLabel={(option) => option.phone}
                defaultValue={currentCountry}
                onChange={handleChangeCountry}
                renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading='lazy'
                            width='20'
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=''
                        />
                        +{option.phone}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Prefijo'
                        inputProps={{
                            ...params.inputProps
                        }}
                        variant={variant ? variant : 'standard'}
                    />
                )}
            />
            <TextField
                type='tel'
                error={alertMessage ? true : false}
                helperText={alertMessage}
                margin='normal'
                size='medium'
                required
                sx={{ flexGrow: 1 }}
                id='phoneNumber'
                label='Número de teléfono'
                name='phoneNumber'
                autoComplete='phoneNumber'
                value={number}
                onChange={handleChangePhoneNumber}
                autoFocus
                variant={variant ? variant : 'standard'}
            />
        </Stack>
    );
}
